import Lenis from '@studio-freight/lenis'
import { gsap } from "gsap";
    
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { Application } from '@splinetool/runtime';
const canvas = document.getElementById('canvas3d');
const app = new Application(canvas);

gsap.registerPlugin(Flip,ScrollTrigger,ScrollToPlugin);

const loaderTitle = new SplitType('#titleLoader')
const textAnimations = document.querySelectorAll(".titles")
const linesAnimations = document.querySelectorAll(".lines")


//lenis
const lenis = new Lenis({
  wheelMultiplier: 0.75,
  touchMultiplier: 0.8,
  syncTouch: true,
  easing: 'cubic-bezier(0.65, 0, 0.35, 1)',
  autoResize: true
})

lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time)=>{
  lenis.raf(time * 700)
})

gsap.ticker.lagSmoothing(0)

//function scrollToTop() {
//  gsap.to(window, {
//    duration: 3,
//    scrollTo: 0,
//    ease: 'easeInOut'
//  });
//}
//window.addEventListener('load', scrollToTop);

ScrollTrigger.matchMedia({
  // large
  "(min-width: 960px)": function () {

    //news 
    gsap.fromTo('.news-content', {
      xPercent: 17,
      ease: 'power2.out'
      },
      {
      xPercent: -20,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.news-section',
        pin: true,
        start: "center center",
        end: "+=1000",
        scrub: true,
        }
      }
    );

    //menu
    gsap.to('header', {
        x: '0',
        scrollTrigger: {
          trigger: '.hero',
          start: 'top 40%',
          end: 'top 20%',
          scrub: true,
        },
        ease: 'power1.inOut'
    });
    
    //logo
    gsap.to('.logo-cont img', {
      yPercent: -100,
      duration: 0.8,
      ease: 'power3.out'
    })
    
    //loader
    gsap.to(loaderTitle.chars, {
      yPercent: -100,
      duration: 0.7,
      stagger: .018,
      ease: 'power3.out'
    })
    
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 90%',
        end: 'top center',
        toggleActions: 'play pause resume reverse'
      }
    });
    
    tl.to('.logo-cont img', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    })
    
    tl.to(loaderTitle.words, {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    tl.to('.mouse', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    //titles
    textAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "words,chars"
      });

      gsap.from(split.chars, {
        yPercent: 200,
        stagger: 0.03,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textLine,
          start: "top 85%",
          end: "top center",
          scrub: true
        }
      });
    });

    //phars
    linesAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "lines,words,chars"
      });

      gsap.from(split.lines, {
        yPercent: 300,
        opacity: 0,
        duration: 1.1,
        ease: 'power2.out',
        stagger: 0.035,
        scrollTrigger: {
          trigger: textLine,
          start: 'top 80%',
          end: 'top center',
          toggleActions: 'play none resume reverse'
        }
      });
    });

    //model
    app
      .load('https://prod.spline.design/ZHowr3Q5cl464lid/scene.splinecode')
      .then(() => {
        const model = app.findObjectByName('model');
        const light = app.findObjectByName('light');
        //console.log(model);
        gsap.set(model.scale, { x: 1, y: 1, z: 1 });
        gsap.set(model.position, { x: 0, y: -100, z: 550 });
        gsap.set(model.rotation, { x: 0, y: 0, z: 0 });
      
        gsap.set(light.scale, { x: 1, y: 1, z: 1 });
        gsap.set(light.position, { x: 0, y: -100, z: 650 });
      
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'top center',
              end: 'top -10%',
              scrub: true
            }
          })
          .from(model.position, { x: 0, y: -300, z: 700, ease: 'power2.inOut' })
          .from(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'center center',
              endTrigger: '.industries-section',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 100, y: -50, z: 500, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: -0.8, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.areas-section',
              start: 'center center',
              endTrigger: '.team-sec',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: -150, y: -40, z: 400, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.6, z: 0, ease: 'power2.inOut' }, '<')
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.logros',
              start: 'top center',
              end: 'bottom 90%',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -35, z: 450, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.ini-section',
              start: 'bottom center',
              end: 'bottom top',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: 0, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.3, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.news-content',
              start: "center center",
              end: "+=1000",
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -130, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: -.2, y: -0.3, z: 0, ease: 'power1.inOut' }, '<');
        

      });

  },

  // medium
  "(min-width: 600px) and (max-width: 959px)": function () {

    gsap.to(loaderTitle.chars, {
      yPercent: -200,
      duration: 1,
      stagger: 0.018,
      ease: 'power3.out'
    })

    gsap.to('header', {
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 40%',
        end: 'top 20%',
        scrub: true,
      },
      duration: 1,
      ease: 'power1.inOut'
    });

    //menu
    let tlMenu = gsap.timeline();

    tlMenu.from('.intro-text', {
      xPercent: -100,
      duration: 1,
      ease: 'power2.out',
      stagger: 0.035,
    })

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 90%',
        end: 'top center',
        toggleActions: 'play pause resume reverse'
      }
    });
    
    tl.to('.logo-cont img', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    })
    
    tl.to(loaderTitle.words, {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    tl.to('.mouse', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    //titles
    textAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "words,chars"
      });

      gsap.from(split.chars, {
        yPercent: 200,
        stagger: 0.03,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textLine,
          start: "top 85%",
          end: "top center",
          scrub: true
        }
      });
    });

    //phars
    linesAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "lines,words,chars"
      });

      gsap.from(split.lines, {
        yPercent: 300,
        opacity: 0,
        duration: 1.1,
        ease: 'power2.out',
        stagger: 0.035,
        scrollTrigger: {
          trigger: textLine,
          start: 'top 80%',
          end: 'top center',
          toggleActions: 'play none resume reverse'
        }
      });
    });

    //news 
    gsap.fromTo('.news-content', {
      xPercent: 0,
      ease: 'power2.out'
      },
      {
      xPercent: -99,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.news-section',
        pin: true,
        start: "center center",
        end: "+=1000",
        scrub: true,
        }
      }
    );

    //model
    app
      .load('https://prod.spline.design/ZHowr3Q5cl464lid/scene.splinecode')
      .then(() => {
        const model = app.findObjectByName('model');
        const light = app.findObjectByName('light');
        //console.log(model);
        gsap.set(model.scale, { x: 0.8, y: 0.8, z: 0.8 });
        gsap.set(model.position, { x: 5, y: -100, z: 550 });
        gsap.set(model.rotation, { x: 0, y: 0, z: 0 });
      
        gsap.set(light.scale, { x: 0.8, y: 0.8, z: 0.8 });
        gsap.set(light.position, { x: 0, y: -100, z: 650 });
      
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'top center',
              end: 'top -5%',
              scrub: true
            }
          })
          .from(model.position, { x: 0, y: -300, z: 700, ease: 'power2.inOut' })
          .from(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'center center',
              endTrigger: '.industries-section',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 55, y: -120, z: 500, ease: 'power2.inOut' })
          .to(model.rotation, { x: -0.2, y: -0.6, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.areas-section',
              start: 'center center',
              endTrigger: '.team-sec',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 30, y: -100, z: 530, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: -0.3, z: 0, ease: 'power2.inOut' }, '<')
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.logros',
              start: 'top center',
              end: 'bottom 90%',
              scrub: true
            }
          })
          .to(model.position, { x: -30, y: -75, z: 490, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.3, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.ini-section',
              start: 'bottom center',
              end: 'bottom top',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -25, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.news-content',
              start: "center center",
              end: "+=1000",
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -130, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: -.2, y: -0.3, z: 0, ease: 'power2.inOut' }, '<');
          
      });

  },

  // small
  "(max-width: 599px)": function () {

    gsap.to(loaderTitle.chars, {
      yPercent: -200,
      duration: 1,
      stagger: 0.018,
      ease: 'power3.out'
    })

    gsap.to('header', {
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 40%',
        end: 'top 20%',
        scrub: true,
      },
      duration: 1,
      ease: 'power1.inOut'
    });

    //menu
    let tlMenu = gsap.timeline();

    tlMenu.from('.intro-text', {
      xPercent: -100,
      duration: 1,
      ease: 'power2.out',
      stagger: 0.035,
    })

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 90%',
        end: 'top center',
        toggleActions: 'play pause resume reverse'
      }
    });
    
    tl.to('.logo-cont img', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    })
    
    tl.to(loaderTitle.words, {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    tl.to('.mouse', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    //titles
    textAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "words,chars"
      });

      gsap.from(split.chars, {
        yPercent: 200,
        stagger: 0.03,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textLine,
          start: "top 85%",
          end: "top center",
          scrub: true
        }
      });
    });

    //phars
    linesAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "lines,words,chars"
      });

      gsap.from(split.lines, {
        yPercent: 300,
        opacity: 0,
        duration: 1.1,
        ease: 'power2.out',
        stagger: 0.035,
        scrollTrigger: {
          trigger: textLine,
          start: 'top 85%',
          end: 'top center',
          toggleActions: 'play none resume reverse'
        }
      });
    });

    //news 
    gsap.fromTo('.news-content', {
      xPercent: 0,
      ease: 'power2.out'
      },
      {
      xPercent: -99,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.news-section',
        pin: true,
        start: "center center",
        end: "+=1000",
        scrub: true,
        }
      }
    );

    //model
    app
      .load('https://prod.spline.design/ZHowr3Q5cl464lid/scene.splinecode')
      .then(() => {
        const model = app.findObjectByName('model');
        const light = app.findObjectByName('light');
        //console.log(model);
        gsap.set(model.scale, { x: 0.8, y: 0.8, z: 0.8 });
        gsap.set(model.position, { x: 5, y: -100, z: 550 });
        gsap.set(model.rotation, { x: 0, y: 0, z: 0 });
      
        gsap.set(light.scale, { x: 0.8, y: 0.8, z: 0.8 });
        gsap.set(light.position, { x: 0, y: -100, z: 650 });
      
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'top center',
              end: 'top -5%',
              scrub: true
            }
          })
          .from(model.position, { x: 0, y: -300, z: 700, ease: 'power2.inOut' })
          .from(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'center center',
              endTrigger: '.industries-section',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 55, y: -120, z: 500, ease: 'power2.inOut' })
          .to(model.rotation, { x: -0.2, y: -0.6, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.areas-section',
              start: 'center center',
              endTrigger: '.team-sec',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 30, y: -100, z: 530, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: -0.3, z: 0, ease: 'power2.inOut' }, '<')
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.logros',
              start: 'top center',
              end: 'bottom 90%',
              scrub: true
            }
          })
          .to(model.position, { x: -30, y: -75, z: 490, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.3, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.ini-section',
              start: 'bottom center',
              end: 'bottom top',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -25, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.news-content',
              start: "center center",
              end: "+=1000",
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -130, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: -.2, y: -0.3, z: 0, ease: 'power2.inOut' }, '<');
          
      });
    

  },

  // all
  all: function () {
      

  },
});